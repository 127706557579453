<template>
  <div class="filter">
    <div class="left-area">
      <div class="label">{{ name }}：</div>
      <div class="values">
        <span
          v-for="item in listData"
          :key="item.id"
          :class="{ active: listActive.includes(item.id) }"
          @click="onChange(item)"
        >
          {{ item.name }}
          <template v-if="item.count >= 0"> （{{ item.count }}） </template>
        </span>
      </div>
    </div>
    <div class="right-area" v-if="$slots.right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import i18n from "@/locale/i18n";
export default {
  name: "ListFilter",
  props: {
    allObj: {
      type: [Object, Boolean],
      default: {
        name: i18n.global.t("CM_All"), // 全部
        id: 0,
      },
    },
    only: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: i18n.global.t("category"),
      // 分类
    },
    dataSource: {
      type: Array,
      default: [],
    },
    activeId: {
      type: Array,
      default: [],
    },
  },
  emits: ["onChange"],
  setup(props, { emit }) {
    let listData = ref([]),
      listActive = ref([0]);

    watch(
      () => props.dataSource,
      (val) => {
        if (val.length) {
          listData.value = props.allObj ? [props.allObj] : [];
          listData.value = listData.value.concat(val);
          listActive.value = props.activeId.length
            ? props.activeId
            : props.allObj
            ? [props.allObj.id]
            : [val[0].id];
        }
      },
      { immediate: true, deep: true }
    );

    const onChange = (item) => {
      if (props.only) {
        listActive.value = [item.id];
      } else {
        if (listActive.value.includes(item.id)) {
          if (item.id != 0) {
            listActive.value.forEach(function (v, i, arr) {
              if (v === item.id) arr.splice(i, 1);
            });
          }
          if (!listActive.value.length) listActive.value = [0];
        } else {
          if (item.id === 0) {
            listActive.value = [0];
          } else {
            if (listActive.value[0] === 0) listActive.value.splice(0, 1);
            listActive.value.push(item.id);
          }
        }
      }
      emit("onChange", listActive.value);
    };

    return {
      listData,
      listActive,
      onChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter {
  .mixinFlex(space-between);
  color: #999;
  font-size: 16px;
  line-height: 30px;
  padding: 14px 10px;
  .left-area {
    .mixinFlex(space-between);
    flex: 1;
  }
  .right-area {
    width: 280px;
  }
  .label {
    margin: 5px 0;
    color: #333333;
  }
  .values {
    flex: 1;
    .mixinFlex(flex-start);
    flex-wrap: wrap;
    span {
      padding: 0 12px;
      height: 32px;
      margin: 5px 12px 5px 0;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;
      user-select: none;
      border: 1px solid transparent;
      .mixinFlex(center; center);
      &:hover {
        color: @color-theme;
      }
      &.active {
        border-color: @color-theme;
        color: @color-theme;
      }
    }
  }
}
</style>
